import React from 'react';

import { ApolloProvider } from '@apollo/react-hooks';

import { BrowserRouter } from 'react-router-dom';

import MobileMenu from './components/MobileMenu';

import AppProvider from './contexts';
import Theme from './config/Theme';
import client from './services/client';

import Routes from './routes';

import ScrollToTop from './utils/ScrollToTop';

function App() {
  return (
    <ApolloProvider client={client}>
      <Theme>
        <AppProvider>
          <BrowserRouter>
            <ScrollToTop />
            <Routes />
            <MobileMenu />
          </BrowserRouter>
        </AppProvider>
      </Theme>
    </ApolloProvider>
  );
}

export default App;
