import styled from 'styled-components';

import { rem, transparentize } from 'polished';

export const MobileMenuContainer = styled.nav`
  position: fixed;
  top: 0;
  right: ${(props) => (props.isOpen ? '0' : '-100%')};
  width: 100%;
  height: 100vh;
  background: ${(props) => props.theme.colors.neutral.dark};
  display: flex;
  align-items: center;
  transition: 0.4s ease-in-out;
  z-index: 1000;
`;

export const CloseButton = styled.div`
  font-size: 32px;
  cursor: pointer;
  color: #ccc;
  position: absolute;
  top: 24px;
  right: 24px;
`;

export const MobileNav = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
`;

export const MobileLink = styled.a`
  font-size: ${rem('24px')};
  font-weight: bold;
  color: #ccc;
  text-decoration: none;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;

  &.is-selected {
    background-color: ${transparentize(0.9, 'white')};
  }

  & + & {
    margin-top: 28px;
  }
`;
