import React, { useRef, useEffect, useContext } from 'react';

import useElementDimensions from '../../hooks/useElementDimensions';

import { ContainerContext } from '../../contexts/ContainerContext';

import { ContainerBlock } from './styles';

function Container({ children, ...props }) {
  const containerRef = useRef();
  const context = useContext(ContainerContext);
  const { width } = useElementDimensions(containerRef);

  useEffect(() => {
    function calcContainerWidth() {
      let containerOffset =
        containerRef.current.getBoundingClientRect().left - 15;
      let calculatedWidth = width + containerOffset;

      return calculatedWidth;
    }

    context.setContainerWidth(calcContainerWidth);
  }, [context, width]);

  return (
    <ContainerBlock ref={containerRef} {...props}>
      {children}
    </ContainerBlock>
  );
}

export default Container;
