import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { PrerenderedComponent } from 'react-prerendered-component';

const prefetchMap = new WeakMap();

function prefetchLazy(LazyComponent) {
  if (!prefetchMap.has(LazyComponent)) {
    prefetchMap.set(LazyComponent, LazyComponent._ctor());
  }

  return prefetchMap.get(LazyComponent);
}

function prerenderedLazy(dynamicImport) {
  const LazyComponent = lazy(dynamicImport);

  return React.memo((props) => (
    <PrerenderedComponent live={prefetchLazy(LazyComponent)}>
      <LazyComponent {...props} />
    </PrerenderedComponent>
  ));
}

const Home = prerenderedLazy(() => import('./pages/Home'));
const WhoWeAre = prerenderedLazy(() => import('./pages/WhoWeAre'));
const ContactUs = prerenderedLazy(() => import('./pages/ContactUs'));
const WorkHere = prerenderedLazy(() => import('./pages/WorkHere'));
const Job = prerenderedLazy(() => import('./pages/Job'));
const Edirectory = prerenderedLazy(() => import('./pages/Edirectory'));
const BrandStyleGuide = prerenderedLazy(() => import('./pages/BrandStyleGuide'));
const NotFound = prerenderedLazy(() => import('./pages/NotFound'));
const Loader = prerenderedLazy(() => import('./components/Loader'));

function Routes() {
  const location = useLocation();
  const activePage = location.pathname;

  useEffect(() => {
    if (activePage === '/') {
      document.querySelector('body').classList.add('fullpage-enabled');
    } else {
      document.querySelector('body').classList.remove('fullpage-enabled');
    }
  }, [activePage]);

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/who-we-are" component={WhoWeAre} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/work-here" component={WorkHere} />
        <Route path="/job/:id" component={Job} />
        <Route path="/products/edirectory-software" component={Edirectory} />
        <Route path="/brand-style-guide" component={BrandStyleGuide} />
        <Route path="/404" component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}

export default Routes;
