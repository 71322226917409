import React from 'react';

import { LoaderContainer, Loading } from './styles';

function Loader() {
  return (
    <LoaderContainer>
      <Loading />
      <Loading />
      <Loading />
      <Loading />
      <Loading />
      <Loading />
      <Loading />
      <Loading />
    </LoaderContainer>
  );
}

export default Loader;
