import React, { createContext, useState } from 'react';

const ContainerContext = createContext();

function ContainerProvider({ children }) {
  const [containerWidth, setContainerWidth] = useState(0);

  return (
    <ContainerContext.Provider value={{ containerWidth, setContainerWidth }}>
      {children}
    </ContainerContext.Provider>
  );
}

export { ContainerContext, ContainerProvider };
