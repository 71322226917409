import styled from 'styled-components';

export const LoaderContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background: ${(props) => props.theme.colors.neutral.dark};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled.div`
  width: 3px;
  height: 12px;
  background: ${(props) => props.theme.colors.brand.base};
  margin: 0 3px;
  display: inline-block;
  animation: opacity-2 1000ms infinite ease-in-out;

  &:nth-child(1) {
    animation-delay: 800ms;
  }
  &:nth-child(2) {
    animation-delay: 600ms;
  }
  &:nth-child(3) {
    animation-delay: 400ms;
  }
  &:nth-child(4) {
    animation-delay: 200ms;
  }
  &:nth-child(5) {
    animation-delay: 200ms;
  }
  &:nth-child(6) {
    animation-delay: 400ms;
  }
  &:nth-child(7) {
    animation-delay: 600ms;
  }
  &:nth-child(8) {
    animation-delay: 800ms;
  }

  @keyframes opacity-2 {
    0% {
      opacity: 1;
      height: 15px;
    }
    50% {
      opacity: 0;
      height: 12px;
    }
    100% {
      opacity: 1;
      height: 15px;
    }
  }
`;
