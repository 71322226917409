import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';

import { initReactI18next } from 'react-i18next';

function setInitialLanguage() {
  const hasLanguageDefined = localStorage.getItem('lang');

  return hasLanguageDefined
    ? hasLanguageDefined
    : navigator.language === 'pt-BR'
      ? 'pt'
      : 'en';
}

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    lng: setInitialLanguage(),
    fallbackLng: 'en',
    ns: [
      'General',
      'Button',
      'Header',
      'Footer',
      'Inputs',
      'Home',
      'WhoWeAre',
      'ContactUs',
      'WorkHere',
      'Job',
      'Edirectory',
      'BrandStyleGuide',
      'Seo',
      '404',
      'FormErrors',
    ],
    defaultNS: 'General',
    keySeparator: false,
  });

export default i18n;
