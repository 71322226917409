import React, { createContext } from 'react';
import { useTranslation } from 'react-i18next';

import '../config/i18n';

const InternalizationContext = createContext();

function InternalizationProvider({ children }) {
  const { t: trans, i18n } = useTranslation();

  function getActiveLang() {
    return i18n.language.split('-')[0];
  }

  function changeLanguage(lang) {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
  }

  return (
    <InternalizationContext.Provider
      value={{ trans, i18n, getActiveLang, changeLanguage }}
    >
      {children}
    </InternalizationContext.Provider>
  );
}

export { InternalizationContext, InternalizationProvider };
