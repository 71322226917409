import styled from 'styled-components';

import { mediaQuery } from '../../config/mediaqueries';

export const ContainerBlock = styled.div`
  width: 100%;
  padding: 0 24px;
  margin: 0 auto;

  ${mediaQuery('xs')`
    padding: 0 48px;
  `};

  ${mediaQuery('sm')`
    max-width: 928px;
    padding: 0 24px;
  `};

  ${mediaQuery('md')`
    max-width: 1152px;
    padding: 0 38px;
  `};

  ${mediaQuery('bg')`
    max-width: 1280px;
    padding: 0 72px;
  `};

  ${mediaQuery('lg')`
    max-width: 1440px;
    padding: 0 140px;
  `};

  ${mediaQuery('xl')`
    max-width: 1600px;
  `};

  ${(props) =>
    mediaQuery('xxl')(`
    max-width: 1920px;
    padding: 0 ${props.lessSpace ? '140px' : '262px'};
  `)}
`;

// export const ContainerBlock = styled.div`
//   width: 100%;
//   padding-right: ${(props) => (props.noPadding ? '0' : '24px')};
//   padding-left: ${(props) => (props.noPadding ? '0' : '24px')};
//   margin-right: auto;
//   margin-left: auto;

//   @media (min-width: 576px) {
//     max-width: 540px;
//   }

//   @media (min-width: 768px) {
//     max-width: 720px;
//     padding-right: 15px;
//     padding-left: 15px;
//   }

//   @media (min-width: 992px) {
//     max-width: 960px;
//     padding-right: ${(props) => (props.noPadding ? '24px' : '0')};
//     padding-left: ${(props) => (props.noPadding ? '24px' : '0')};
//   }

//   @media (min-width: 1200px) {
//     max-width: 1140px;
//   }
// `;
