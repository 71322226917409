import React, { useState, createContext } from 'react';

const MobileMenuContext = createContext();

function MobileMenuProvider({ children }) {
  const [menuState, setMenuState] = useState(false);

  function handleMenuState() {
    setMenuState(!menuState);
  }

  return (
    <MobileMenuContext.Provider
      value={{ menuState, setMenuState, handleMenuState }}
    >
      {children}
    </MobileMenuContext.Provider>
  );
}

export { MobileMenuContext, MobileMenuProvider };
