import { useContext } from 'react';

import { InternalizationContext } from '../contexts/InternalizationContext';

function useTrans() {
  const context = useContext(InternalizationContext);

  if (!context) {
    throw new Error('Missing context dependecies');
  }

  return context;
}

export default useTrans;
