import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import './assets/styles/reset.css';
import './assets/styles/baseStyle.css';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  ReactDOM.render(<App />, rootElement);
}
