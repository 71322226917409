const breakpoints = {
  xs: 576,
  sm: 768,
  md: 1024,
  bg: 1280,
  lg: 1440,
  xl: 1600,
  xxl: 1920,
};

export const mediaQuery = (key, variation = 'min-width') => {
  return (style) =>
    `@media screen and (${variation}: ${
      breakpoints.hasOwnProperty(key) ? breakpoints[key] : key
    }px) { ${style} }`;
};
