import React, { Suspense } from 'react';

import { InternalizationProvider } from './InternalizationContext';
import { MobileMenuProvider } from './MobileMenuContext';
import { ContainerProvider } from './ContainerContext';

import Loader from '../components/Loader';

function AppProvider({ children }) {
  return (
    <Suspense fallback={<Loader />}>
      <InternalizationProvider>
        <MobileMenuProvider>
          <ContainerProvider>{children}</ContainerProvider>
        </MobileMenuProvider>
      </InternalizationProvider>
    </Suspense>
  );
}

export default AppProvider;
