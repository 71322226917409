import { linearGradient } from 'polished';

export const theme = {
  colors: {
    brand: {
      base: '#43FECB',
      dark: '#2DE0AF',
      primary: '#0271FC',
    },
    neutral: {
      base: '#333333',
      dark: '#242424',
      light: '#7F7F7F',
      light2: '#EFF3F5',
      light3: '#E5EAED',
    },
    state: {
      error: '#FC0262',
    },
    gradient: {
      primary: linearGradient({
        colorStops: ['#025AC8', '#65AAFF'],
        toDirection: 'to right',
        fallback: '#025AC8',
      }),
      light: linearGradient({
        colorStops: ['#EFF3F5', '#FEFEFE'],
        toDirection: 'to right',
        fallback: '#EFF3F5',
      }),
      light2: linearGradient({
        colorStops: ['#EFF3F5', '#EBEFF1'],
        toDirection: 'to right',
        fallback: '#EFF3F5',
      }),
      dark: linearGradient({
        colorStops: ['#333333', '#242424'],
        toDirection: 'to right',
        fallback: '#333333',
      }),
    },
  },
};
