import { useState, useEffect } from 'react';

function useElementDimensions(ref) {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    function getDimensions() {
      return {
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      };
    }

    function handleResize() {
      setDimensions(getDimensions());
    }

    if (ref.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);

  return dimensions;
}

export default useElementDimensions;
