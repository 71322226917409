import React, { useContext } from 'react';

import useTrans from '../../hooks/useTrans';

import { NavLink } from 'react-router-dom';

import { MobileMenuContext } from '../../contexts/MobileMenuContext';

import { IoMdClose } from 'react-icons/io';

import Container from '../Container';

import {
  MobileMenuContainer,
  CloseButton,
  MobileNav,
  MobileLink,
} from './styles';

function MobileMenu({ isOpen }) {
  const { trans } = useTrans();
  const context = useContext(MobileMenuContext);

  return (
    <MobileMenuContainer isOpen={context.menuState}>
      <Container>
        <CloseButton onClick={() => context.handleMenuState()}>
          <IoMdClose />
        </CloseButton>
        <MobileNav>
          <MobileLink
            as={NavLink}
            to="/who-we-are"
            activeClassName="is-selected"
          >
            {trans('Header:WhoWeAre')}
          </MobileLink>
          <MobileLink
            as={NavLink}
            to="/contact-us"
            activeClassName="is-selected"
          >
            {trans('Header:ContactUs')}
          </MobileLink>
          <MobileLink
            as={NavLink}
            to="/work-here"
            activeClassName="is-selected"
          >
            {trans('Header:WorkHere')}
          </MobileLink>
        </MobileNav>
      </Container>
    </MobileMenuContainer>
  );
}

export default MobileMenu;
